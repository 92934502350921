.appBar {
  //position: fixed;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
  z-index: 999;
  border-bottom: 1px solid black;
}

.appBarContainer {
  padding: 0 20px;
}

.appBarContainer:after {
  content: "";
  display: table;
  clear: both;
}

.appBarContainer .logoContainer {
  float: left;
}

.appBarContainer .signOutBtn {
  float: right;
}

.appBarLogo {
  min-height: 30px;
}
