html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  overflow-x: hidden !important;
  line-height: 1.5;
  letter-spacing: 0.3px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: $black;
}

a {
  text-decoration: none;
  color: $primary;
}

img {
  max-width: 100%;
}

div,
section {
  box-sizing: border-box;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.center {
  text-align: center;
}

.button {
  padding: 10px;
}

.centerHolder {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
